@import 'theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&display=swap');

body{font-family: 'Manrope', sans-serif !important;margin: 0;padding: 0;}
a{ text-decoration: none;}
.fw-800{ font-weight: 800;}
.fw-500{ font-weight: 500;}
.fw-600{ font-weight: 600;}
.text-primary{ color: #68013F !important;}
strong{ font-weight: 600;}
section{padding: 30px 0;}

header {   
  .navbar-brand img{ width: 95px;}
  .nav-link{ color: #fff; font-size: 13px; padding: 10px 20px !important; background: none; border: none; appearance: none;}
  .nav-link.active{ font-weight: 600; color: #0AA537 !important;}
  .nav-link img{ margin: -5px 5px 0 0;}
  .dropdown-toggle::after { display: none; }
  // .dropdown-box:hover .dropdown-menu{ display: block !important;}
  .dropdown-menu{ padding: 0; border: none; width: auto;border-radius: 10px;background-color: #fff;overflow: hidden;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;transform: translate(0px, 34px);}
  .dropdown-menu .dropdown-item{ font-size: 14px; padding: 10px 20px;}
  .dropdown-menu .dropdown-item:hover, .dropdown-item.active, .dropdown-item:active { background-color: #fff; color: #0AA437;}
  .navbar-toggler{ border:none; box-shadow: none;}
  .navbar-toggler:focus { box-shadow: none;}
  .navbar-collapse{ background-color: #0AA537;}
  .nav-link:hover{color: #fff;}
  .form-group .input-group{margin-bottom: 0 !important;}
  .form-group .search-bar .form-control{height: 50px;border-radius: 6px;background-color: #F5F5F5;color: 222;border-color: #DDDDDD;border-width: 1.3px;font-size: 14px;padding: 5px 40px 5px 10px;}
  .form-group .search-bar .form-control:focus{box-shadow: none;}
  .mail-box{position: relative;padding-left: 8px;}
  .mail-box .iconify{position: absolute;left: 0;top: 5px;font-size: 20px;}
  .location-box {position: relative;padding-left: 4px;}
  .location-box .iconify{position: absolute;left: 0;top: 5px;font-size: 20px;color: #fff;}
  .order-box p{margin-bottom: 0;color: #333333;}
  .order-box span{color: #0AA537;font-size: 18px;font-weight: 600;}
  .order-box{position: relative;}
  .order-box p{padding-left: 26px;font-size: 13px;}
  .order-box .iconify-call{color: #222;position: absolute;left: 0px;top: 3px;font-size: 17px;}
  .order-box .iconify-eye{color: #808080 !important;right: 10px;top: -1px;font-size: 15px;}
  .order-boxa:hover .iconify-eye{color: #0AA437;}
  .card-box ul{margin: 0;padding: 0;text-decoration: none;list-style: none;}
  .card-box ul li{display: inline-block !important;list-style: none;margin-left: 5px;}
  .account-box .nav-link{color: #222 !important;}
  .account-box{position: relative;margin-right: 5px;}
  .account-box .iconify{position: absolute;left: 0;top: 6px;}
  .account-cart{position: relative;padding-left: 18px;}
  .account-cart .iconify{position: absolute;left: 0;top: 2px;font-size: 16px;}
  .account-cart .cart-count{position: absolute;width: 15px;height: 15px;background-color: #0AA537;border-radius: 50px;left: 0;display: inline-block;line-height: 15px;text-align: center;font-size: 9px;color: #fff;top: -7px;}
  .search-icon{position: relative;}
  .account-cart a{color: #222;font-size: 13px;}
  .search-icon .iconify{position: absolute;right: 8px;top: 8px;z-index: 999;font-size: 18px;}
  .card-box{float: right;}
  .iconify-arrow-left{position: absolute;right: 33px;top: 3px;font-size: 22px;}
  .arrow-header{position: absolute;right: 20px;top: 5px !important;font-size: 28px !important;}
  .user-account a{color: #222;}
 }
 .scrolled .header-top {
  -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
 }
.account-box .nav-link{padding: 10px 30px 10px 20px  !important;}

// Banner
.carousel-control-prev{left: -105px;width: 10%;height: 10%;margin: auto;}
.carousel-control-next{right: -105px;width: 10%;height: 10%;margin: auto;}
.carousel-control-prev-icon{ background: url('../public/assets/images/icon-arrow-left.svg') no-repeat center center /cover !important; opacity: 1;}
.carousel-control-next-icon{ background: url('../public/assets/images/icon-arrow-right.svg') no-repeat center center /cover !important; opacity: 1;}
.carousel-indicators [data-bs-target] { background-color: #fff;  width: 10px; height: 10px; margin: 0px 5px; border-radius: 50px;  border: none;border: 1px solid #0AA537;}
.carousel-indicators .active { background-color: #0AA537;}
.accordion-button::after { background: url('../public/assets/images/icon-arrow-accordion.svg') no-repeat center center !important; }
.navbar{ padding:0; background: #0AA537;}
.banner{ padding-bottom: 50px;}
section{ padding: 40px 0;}
.carousel-item img{width: 100%;}
.carousel-inner{border-radius: 20px;}
.carousel-item{transition: transform .4s ease-in-out !important;}

// footer
.footer-section{background: #DAF1E1;padding: 60px 0px 40px 0px;}
.footer-logo img{width: 100px;}
.footer-widget.logoftr {text-align: center;}
.footer-widget.logoftr h5 {font-size: 18px;font-weight: 600; color: #000;}
.footer-widget p{font-size: 16px;margin-bottom: 0;font-size: 14px;color: #333;}
.footer-widget h3{font-size: 18px;font-weight: 600;margin-bottom: 20px;color: #000;}
.footer-widget ul li{list-style: none;margin-bottom: 10px;}
.footer-widget ul li button{-webkit-appearance: none !important;padding: 0;background: none !important;box-shadow: none !important;border: none !important;font-size: 14px;color: #333;}
.footer-widget ul{margin: 0;padding: 0;}
.footer-widget ul li a{color: #333;font-size: 14px;}
.footer-widget ul li a:hover{color: #0AA437;}
.footer-widget ul li button:hover{color: #0AA537;}
.text-green{color: #0AA537;font-weight: 600;font-size: 18px;}
.text-mail{color: #000;}
.text-mail:hover{color: #0AA537;}
.social-icon h3{font-size: 18px;font-weight: 700;}
.social-icon{margin-top: 15px;}
.social-icon ul{margin: 0;padding: 0;}
.social-icon ul li{list-style: none;display: inline-block;margin-right: 8px;font-size: 20px;}
.social-icon ul li .iconify{color: #0AA537;}
.copyright{text-align: center;font-size: 14px;padding-top: 40px;margin-top: 40px;border-top: 1px solid rgba(0, 0, 0, 0.06);}
.copyright p{margin-bottom: 0;color: #333;}

// Home
.product-heading h3{text-align: center;color: #333333;font-weight: 600;font-size: 32px; margin-bottom: 0;}
.category-box{padding: 20px;}
.Category-title{text-align: center;position: absolute;right: 10px; z-index: 99;}
.Category-title span{color: #0AA537;letter-spacing: 14px;text-transform: uppercase;font-weight: 600;font-size: 20px;}
.Category-title h3{text-transform: uppercase;font-weight: 600;color: #333;font-size: 32px;text-transform: uppercase;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;}
.Category-title .shop-btn{background: #0AA537;padding: 7px 25px;color: #fff;border-radius: 50px;font-size: 16px;text-transform: uppercase;font-weight: 600;display: inline-block;transition: all 0.4s ease}
.bg-green{background: #DAF1E1;}
.category-box{border-radius: 15px;height: 250px;width: 100%;position: relative;transition: all 0.4s ease;margin-bottom: 50px;}
.offer-box{background: #0AA537;width: 60px;height: 60px;overflow: hidden;color: #fff;border-radius: 8px 8px 50px 50px;text-align: center;font-size: 14px;line-height: 16px;padding: 4px;position: absolute;left: 15px;top: 15px;z-index: 9;}
.offer-box strong{font-weight: 600;font-size: 18px;}
.offer-green{background: #0AA537;color: #fff;}
.offer-orange{background: #F07C00 !important;color: #fff !important;}
.btn-orange{background: #F07C00 !important;color: #fff !important;}
.fruits-img{width: 255px;height: 240px;overflow: hidden; position: absolute; top: 45px; left: 3px;transition: all 0.4s ease !important; z-index: 9;
  img{width: 100%;height: 100%;object-fit: contain;}
 }
.bg-orange{background: #F1891933;}
.text-orange{color: #F07C00;}
.product-box{background: #fff;border-radius: 15px;box-shadow: 0px 4px 0px 0px rgba(10, 165, 55, 0.15);padding: 13px;position: relative;margin-bottom: 20px;min-height: 380px;}
.product-img{ width: 100%;height: 260px;overflow: hidden;text-align: center;position: relative;padding: 15px;}
.product-img{background: url(../public/assets/images/logo.png) no-repeat center center; background-size: 50%;}
.product-box:hover .product-img::after{width: 100%;background-color: rgba(255,255,255,0);-webkit-transition: all 0.4s ease-in-out;-moz-transition: all 0.4s ease-in-out;transition: all 0.4s ease-in-out;}
.product-img::after{ content: '';position: absolute;top: 0;left: 0;width: 0;height: 100%;background-color: rgba(255,255,255,0.4);-webkit-transition: none;-moz-transition: none;transition: none;}
.product-img img{width: 100%;object-fit: cover;background: #fff !important;}
.product-icon img{background: none !important;}
.product-title h4{font-size: 15px;color: #333;font-weight: bold; display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden}
.product-title{margin-bottom: 10px;height: 35px;margin-top: 10px;}
.special-list{background: #DAF1E1;}
.product-offer-box{background: #0AA537;width: 50px;height: 50px;overflow: hidden;color: #fff;border-radius: 8px 8px 50px 50px;text-align: center;font-size: 14px;line-height: 16px;padding: 7px;position: absolute;left: 5px;top: 5px;}
.product-icon{position: absolute;right: 15px;bottom: 15px;}
.product-icon.product-icon-detail{right: 15px; bottom: 15px; z-index: 9;}
.price-list h5{font-size: 12px;color: #0AA537;margin-bottom: 0;}
// .price-list{margin-top: 15px;} 
.price-tag{font-size: 20px;font-weight: 600;margin-right: 5px;}
.price-low{text-decoration-line: line-through;font-size: 12px;color: #9F9F9F;}
.product-heading{margin-bottom: 30px;}
.fruit-two{width: 256px !important; height: 278px !important;overflow: hidden; position: absolute;top: 8px !important;left: -16px !important;}
.view-btn a{padding: 10px 50px;border-radius: 50px;border: 2px solid #0AA537;color: #0AA537;font-weight: 600;font-size: 16px;transition: all 0.4s ease;display: inline-block;}
.view-btn{text-align: center;margin-top: 30px;transition: all 0.4s ease;}
.view-btn a:hover{background-color: #fff;border-color: #fff;}
.whychoose-box h4{color: #0AA537;font-size: 20px;font-weight: 600;}
.whychoose-box p{font-size: 13px;color: #6c757d;}
.choose-img{margin-bottom: 15px;position: relative;}
.app-info{background: #DAF1E1;padding: 30px;border-radius: 20px;}
.app-logo{width: 300px;height: 300px;background: #fff;border-radius: 50%;display: flex;align-items: center;justify-content: center;}
.app-logo img{display: flex;align-items: center;justify-content: center;width: 180px;}
.app-detail h3{font-size: 32px;color: #333;font-weight: 300;}
.app-detail h4{font-size: 38px;font-weight: 600;color: #333;margin-bottom: 0;}
.cart-btn a{background: #0AA537;padding: 8px 10px 8px 10px;font-size: 12px;border-radius: 6px;color: #fff;text-align: center;}
.select-card .cart-btn a{display: inline-block;}
.select-card .cart-btn{text-align: right;}
.select-card .btn-add{margin-top: 10px;text-align: start;}  
.cart-btn .iconify{font-size: 16px;margin-right: 3px;}
.cart-btn{position: relative;}
.form-option .form-select{background-color: #F5F5F5;height: 30px;border: none !important;padding: 3px 10px;font-weight: 500;font-size: 13px;border-radius: 6px !important;margin-bottom: 10px;}
.form-option .form-select:focus{border: none;box-shadow: none;}
.fruit-three{width: 248px !important;height: 222px !important;overflow: hidden;position: absolute; top: 65px !important;left: 3px !important;}
.mt-space{margin-top: 130px;}
.navbar-logo{display: none;}
.location-mobile-view{display: none;}
.search-icon a{color: #000;}
.search-cart{width: 490px;}
.search-cart .account-cart{display: none;}
.header-modal .modal-title{font-size: 20px;font-weight: 600;color: #000;}
.modal-header{border: none !important;padding: 0;}
.modal-body{border: none !important;padding: 0;}
.form-modal p{font-size: 16px;color: #555555;font-weight: 400;}
.form-location .form-control{height: 50px;border-radius: 6px;background-color: #DAF1E1;color: #222;font-size: 14px;border: none;}
.form-location .form-control:focus{box-shadow: none;}
.form-location .form-control::placeholder{color: #929191;}
.valu-text .form-control{color: #929191;}
// .form-location .form-select option{background-color: #fff;color: #0AA437;border: none;}

.check-btn a{padding: 10px 20px;background: #0AA437;border-radius: 6px;display: block;text-align: center;color: #fff;font-size: 18px;font-weight: 500;}
.notified{color: #b5b5b5 !important;font-size: 14px !important;}
.form-location{position: relative;}
.error-text{position: absolute;font-size: 13px;right: 0;bottom: -20px;color: #ff0033;}
.modal-footer{border: none !important;padding: 0;}
.skip-btn a{color: #000;font-size: 14px;font-weight: 500;}
.skip-btn{text-align: end;}
.modal-content{border-radius: 20px !important;border: none !important;}
.btn-close{box-shadow: none !important;}
.user-account{display: none;}
.header-nxt{background: #0AA437;min-height: 100vh;}
.login-cont{background: #fff;padding: 25px;border-radius: 30px; position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 450px;box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.15);}
.login-cont .form-group .form-control{height: 55px;border-radius: 6px;background-color: #DAF1E1;color: 222;font-size: 14px;border: none;}
.fwd-password{color: #222;text-align: end;font-size: 14px;}
.login-cont .form-group .form-control:focus{box-shadow: none;}
.forgot-row{text-align: end;}
.login-logo{text-align: center;}
.login-logo img{width: 110px;}
.btn-submit{background-color: #0AA437 !important;border: none !important;font-size: 18px ;font-weight: 600;height: 50px;border-radius: 6px;}
.login-cont h3{font-size: 24px;}
.product-offer-box span strong{font-size: 16px;}
.reg-text{font-size: 14px;text-align: center;}
.bg-orange:hover .btn-orange{background: #fff !important;color: #000 !important;transition: all 0.3s ease;}
.bg-green:hover .btn-green{background: #fff;color: #000;transition: all 0.4s ease !important;}
.popup-home {
  .location-modal {
    margin-top: 50px;
  }
}
.popup-box{padding: 30px;position: relative;}
.popup-box.popup-home{height: 560px;}
.logo-point img{width: 150px;}
.logo-point{text-align: center;margin-bottom: 25px;}
.btn-head .btn-close{position: absolute;top: 20px;right: 20px;color: #000 !important;opacity: 1 !important;}
.delivery-box h4{font-size: 20px;font-weight: 600;}
.delivery-box p{font-size: 16px;color: #555;}
.vehicel-img{margin-top: 50px;}
.delivery-box{color: #333;text-align: center;}
.popup-img{position: absolute;left: 0;bottom: 0;}
.header-modal h4{font-size: 20px;font-weight: 600;}
.nav-link:focus{color: #fff;}
.category-box:hover .fruits-img{margin-top: -20px !important;transition: all 0.4s ease;}
.off-text{font-size: 10px !important;}
.pb-sapce{padding-bottom: 0 !important;}
  .pt-space{padding-top: 0 !important;}
// .logo-box{width: 180px;}
.whychoose-box:hover .choose-img{margin-top: -15px;transition: all 0.4s ease;}
.whychoose-box {text-align: center;}
.choose-img{transition: all 0.4s ease;}
.label-text{font-size: 14px;color: #555;}
.product-img:hover .zoom-effect{ transform: scale(1.1);object-fit: cover;transition: all 0.4s ease;}
.product-img img{overflow: hidden;transition: all 0.4s ease;}
.slide-img img{width: 80%;}
.popup-box .carousel-indicators{bottom: -55px !important;}
.account-sign {color: #555 !important;font-size: 14px !important;font-weight: 400 !important;}
.account-sign span a{font-size: 14px !important;color: #0AA437;}
.popup-img img{width: 100%;}
.dropdown-item{position: relative;padding-left: 30px !important;}
.dropdown-item .iconify-drop{position: absolute;left: 10px;top: 8px;font-size: 16px;}
.works-box p{font-size: 16px;color: #333;font-weight: 600;line-height: 24px; text-align: justify;}
.works-box p span{color: #0AA437;}
.works-box{text-align: center;position: relative;}
.about-details{background: #DAF1E1;padding: 40px 0;}
.works-img{width: 200px;height: 200px;overflow: hidden;transition: all 0.4s ease !important;border-radius: 10px 10px 50% 50%;margin: 0 auto 10px;border: 3px solid #22A636;position: relative; }
.works-img img{width: 100%;height: 100%;object-fit: cover;}
.about-bg{background-color: #fff;border-radius: 20px;overflow: hidden;}
// .product-heading h3 span{font-size: 32px !important;font-weight: 600;}
.popup-banner{ border-right: 1px solid hsla(0,0%,87%,.867);height: 100%;}
.popup-banner .carousel .carousel-control-prev{display: none;}
.popup-banner .carousel .carousel-control-next{display: none;}
.header-bottom{background: #fff;padding: 8px 0 8px;}
.works-box:hover .works-img{margin-top: -15px !important;transition: all 0.4s ease !important;}

.store-group {
  background: #fff; border-radius: 10px; padding: 10px;
  .qr-img {
    margin-bottom: 5px;
    img{width: 100%;}
  }     
  .appstore-img {
    img{width: 100%;}
  }
}

// .appstore-img img{width: 100%;}

.footer-logo{margin-bottom: 20px;}
.resend{font-size: 14px !important;color: #0AA437 !important;text-align: end;}
.resend a{color: #0AA437 !important;}
.otp{font-size: 14px !important;color: #0AA437 !important;text-align: start;}
.works-box .steps{position: absolute;top: -35px;left: -35px;font-size: 50px;font-weight: 600;color: #DAF1E1;z-index: 9;}
.app-img img{width: 100%;}
.input-group .form-control input{font-size: 12px;}
 #remove-top{ transition: top 0.3s;}
.detail-box{box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;min-height: 380px;}
.item-list p{font-size: 16px;color: #333;font-weight: 600;margin-bottom: 0;}
.price-option .form-select{background-color: #F5F5F5;height: 33px;border: none !important;padding: 3px 10px;font-weight: 500;  font-size: 14px;box-shadow: none;display: inline-block !important;width: 160px;}
.product-item{margin-bottom: 20px;}
.price-option span{font-size: 16px;font-weight: 600;margin-right: 5px;color: #333;}
.product-dt-right h4{font-size: 18px;font-weight: 600;}
.buy-box{margin-top: 15px;}
.border-line-top{border-top: 1px solid rgba(0, 0, 0, 0.06);margin-top: -35px;margin-bottom: 20px;}
.buy-box a .iconify{font-size: 15px;color: #222;margin-right: 2px;}
.buy-box a{color: #333;margin-right: 10px;position: relative;font-size: 14px;}
.detail-info{margin-top: 15px;}
.detail-info p{font-size: 14px;color: #6c757d;margin-bottom: 0;}
.readmore{color: #0AA437;font-size: 14px;font-weight: 500;}
.readmore:hover{color: #0AA437;font-weight: 500;}
.product-dt-right h4{font-size: 24px;}
.product-dt-right .price-tag{font-size: 24px;}
.product-dt-right .price-list h5{font-size: 14px;}
.price-dt-right .price-low{font-size: 14px;}
.select-card h4{font-size: 16px;}
.select-card{margin-top: 5px;}
// .select-card .form-option .form-select{height: 41px;}
.select-card .btn-add a{font-size: 12px; padding: 10px 15px;}
.select-card .btn-add .iconify svg{font-size: 18px;}
.breadcrumb{padding: 20px 0;margin: 0;}
.breadcrumb ul{list-style: none;margin: 0;padding: 0;}
.breadcrumb ul li.active{font-weight: 500;}
.breadcrumb ul li{display: inline-block;font-size: 14px;}
.breadcrumb ul li a{color: #6c757d;position: relative;padding-right: 25px;}
.breadcrumb ul li a .iconify{position: absolute;right: 1px !important;top: 0px;font-size: 22px; }
// .breadcrumb ul li.active{font-size: 16px;}
.detail-info h4{font-size: 20px;}
// carosul
.owl-dots .owl-dot span{background: #fff !important;border: 1px solid #0aa537 !important;border-radius: 50px !important;margin: 0 5px !important;width: 15px !important;height: 15px !important;border: 1px solid #0aa537 !important;}
.owl-theme .owl-dots .owl-dot.active span{background: #0AA437 !important;}
.product-dt-view img{width: 100%;}
.tags{margin-top: 20px;}
.tags ul{list-style: none;margin: 0;padding: 0;}
.tags ul li{display: inline-block;margin-right: 10px;color: #222;font-size: 14px;}
.tags ul li .iconify{font-size: 14px;}
.tags ul li a{font-size: 12px;padding: 5px 10px;border: 1px solid #ced4da;border-radius: 6px;color: #6c757d;}
.delivery-value p{color: #6c757d;}
.delivery-value{margin-top: 20px;font-size: 14px;}
.cart-body{border-radius: 8px;padding: 10px 0px;margin-bottom: 15px;}
.cart-img{width: 100px;height: 100px;overflow: hidden;}
.cart-img img{width: 100%;object-fit: cover;}
.cart-price p{font-size: 14px;margin-bottom: 5px;color: #222;}
.cart-table tr td{padding: 10px;vertical-align: middle;}
.cart-left{padding: 0px 0px;border-radius: 6px;}
.cart-table {margin-bottom: 0;}
.cart-option p{font-weight: 600;margin-right: 10px;}
.form-option p{font-size: 14px;font-weight: 600;}
.price-value{color: #0AA437;font-size: 20px;margin-bottom: 0;}
.text-content{font-size: 14px;color: #6c757d;margin-bottom: 5px;}
.price-save{font-size: 14px;font-weight: 600;}
.qty-button{display: flex;width: 30px;height: 30px;background: #e3e0e0 !important;border-radius: 50px !important;text-align: center;justify-content: center;align-items: center;border: none !important;padding: 14px;line-height: 15px;}
.qty-button .iconify{color: #222;}
.qty-count .form-control{padding: 0;border: none;text-align: center;background-color: transparent;box-shadow: none;width: 30px;margin-bottom: 0;}
.quantity-price{display: flex;justify-content: space-between;align-items: center;margin-top: 10px;}
.text-success{color: #0AA437 !important;font-size: 14px;}
.text-error{color: #f71e0f !important;font-size: 14px;}
.save-action .iconify{color: #cecdcd;font-size: 26px;vertical-align: baseline;}
.save-action{text-align: center;}
.table-line{border-bottom: 1px solid #ccc;}
.coupon-box .form-control{border-color: #DDDDDD;background-color: #F5F5F5;box-shadow: none;width: 30px;margin-bottom: 0;height: 35px;font-size: 12px;width: 20px;border-radius: 6px 0px 0px 6px;}
.apply-btn{padding: 8px 20px;background-color: #0AA437;color: #fff;font-size: 12px;border-radius: 0px 6px 6px 0px;}
.coupan-apply{display: flex;}
.apply-btn:hover{color: #fff ;}
.coupan-content ul{margin: 0;padding: 0;border-bottom: 1px solid #ccc;}
.coupan-content ul li{display: flex;justify-content: space-between;margin-bottom: 8px;align-items: center;line-height: 18px;}
.coupan-content ul li h4{font-size: 14px;color: #222;min-width: 115px;text-align: right;margin-bottom: 0;}
.summary-total ul li{display: flex;justify-content: space-between;}
.product-cart-list span{font-size: 13px;color: #222;}
.summary-total ul{margin: 0;padding: 0;}
.summary-total{margin-top: 10px;}
.summary-total ul li h4{color: #000;font-size: 16px;margin-bottom: 0;}
.cart-button a{color: #fff;background-color: #0AA437;padding: 10px 40px;display: block;font-size: 13px;text-align: center;border-radius: 6px;}
.cart-button{margin: 8px 0;}
.cart-button button{-webkit-appearance: none !important;box-shadow: none !important;border: none !important;background-color: #0aa437;border-radius: 6px;color: #fff;width: 100%;font-size: 13px;padding: 10px 40px;text-align: center;}
.return-button a{color: #000000;background-color: #dddddd;padding: 10px 40px;display: block;font-size: 13px;text-align: center;border-radius: 6px;}
.return-button a .iconify{font-size: 16px;}
.cart-total h4{font-size: 20px;}
.cart-title h4{font-size: 20px;color: #000;margin-bottom: 0;}
.cart-option .form-select{background-color: #fff;height: 28px;width: 75px;border: none !important;padding: 3px 10px;font-weight: 500;font-size: 14px;border-radius: 6px !important;box-shadow: none;}
.address-box{background: #fff;padding: 20px;border-radius: 6px;position: relative;margin-bottom: 5px;}
.address-box p{color: #6c757d;font-size: 14px;margin-bottom: 5px;}
.address-box p span{color: #222;margin-bottom: 5px;}
.address-type span{color: #fff;padding: 2px 8px;position: absolute;left: 160px;top: 20px;background: #0AA437;font-size: 12px;border-radius: 6px;}
.address-header{display: flex;align-items: center;margin-bottom: 10px;}
.address-header h5{font-size: 18px;color: #000;margin-bottom: 2px;margin-left: 10px;}
.accordion-box .accordion-item{box-shadow: none;margin-bottom: 15px;border: none;}
.accordion-box .accordion-button{border-radius: 6px !important;}
.accordion-box .accordion-button:focus{box-shadow: none;}
.accordion-box .accordion-button:after {background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;}
.order-image{width: 30px;height: 30px;object-fit: contain;}
.payment-input .form-control {height: 50px; border-radius: 6px;background-color: #F5F5F5;color: 222; border-color: #DDDDDD;border-width: 1.3px;font-size: 14px;box-shadow: none;}
.cart-title.headingbtn{display: flex;align-items: center;justify-content: space-between;

  a{color: #0AA437;font-size: 14px;font-weight: 600;}
 }
.cart-body .delivery-option .address-title{display: flex;align-items: center;}
.cart-body .delivery-option .address-title h5{font-size: 16px;color: #000;margin-bottom: 0;margin-left: 5px;}
.cart-body .delivery-option{background: #fff;padding: 10px 15px;display: flex;border-radius: 6px;}
.edit-option ul{list-style: none;margin: 0;padding: 0;}
.edit-option{position: absolute;right: 10px;top: 15px;}
.edit-option ul li{display: inline-block;margin-right: 10px;}
.edit-option ul li a{color: #333;}
.edit-option ul li a .iconify{font-size: 17px;}
.edit-option ul li a:hover{color: #0AA437;}
.text-bold{color: #000 !important;font-size: 14px;font-weight: 600;}
.text-left{text-align: left !important;}
.form-location .form-select{height: 50px;border-radius: 6px;background-color: #DAF1E1;color: #222;font-size: 14px;border: none;box-shadow: none;}
.inputbt-space{margin-bottom: 10px;}
.input-textarea{margin-bottom: 10px;}
.input-textarea .form-control{height: 100px;resize: none;}
.add-button{text-align: end;}
.btn-cancel:active{color: #000 !important;}
.btn-cancel:hover{color: #000;} 
.btn-submit:hover{background-color: #06852c !important;}
.btn-cancel{background-color: #eee !important;margin-right: 10px;border: none !important;font-size: 16px;font-weight: 600;color: #000;height: 50px;border-radius: 6px;}
.btn-size{height: 50px;font-size: 16px;}
.total-line span{font-size: 16px;}
.total-line strong{font-size: 16px;}
.order-card .order-content p a{color: #000;}

    // My Orders
    .order-box .success-icon .iconify{color: #0AA437;font-size: 80px;}
    .order-box .success-icon{margin-bottom: 10px;}
    .order-box h4{font-size: 22px;color: #000;}
    .order-box p{font-size: 16px;margin-bottom:8px;}
    .order-box p a{color: #0AA437;font-size: 18px;}
    .btn-order{font-size: 16px;}
    .accordion-box .accordion .accordion-item .accordion-body{padding: 10px;}
    .order-top h4{font-size: 20px;color: #000; margin-bottom: 0;}
    .order-head{background: #F5F5F5;padding: 10px 10px;border-radius: 6px 6px 0px 0px;border-bottom: 1px solid #ccc;}
    .order-menu{position: sticky; top: 145px;}
    .my-order{position: relative;}
    .my-order::before{bottom: 0;content: "";left: 0;position: absolute;top: -1px;width: 27%;}
    .order-menu ul{list-style: none;margin: 0;padding: 0;}
    .order-menu ul li {margin-bottom: 10px;}
    .order-menu ul li a .iconify{margin-right: 5px;font-size: 16px;}
    .order-menu ul li a{color: #000;font-size: 14px;padding: 5px 0px;display: inline-block;display: block;background: #fff;border-radius: 6px;}
    .order-menu ul li a:hover{color: #0AA437;}
    .order-menu ul li a :active{color: #fff;background: #0AA437;}
    .track-id a{color: #0AA437;font-size: 14px;}
    .track-cart a{color: #fff;font-size: 12px;padding: 5px 5px;border: 1px solid #0AA437;border-radius: 6px;background: #0AA437;}
    .track-cart a .iconify{margin-right: 1px;font-size: 14px;}
    .track-cart{text-align: end;}
    .my-order-img{width: 70px;height: 70px;overflow: hidden;}
    .my-order-img img{width: 100%;object-fit: cover;}
    .delivery-product a{color: #000;}
    .delivery-product p{margin-bottom: 5px;font-size: 14px;}
    .delivery-product h4{font-size: 14px;color: #000;}
    .delivery-detail p{font-size: 14px;margin-bottom: 5px;}
    .order-body{padding: 10px;}
    .order-footer{padding: 10px}
    .order-footer {border-top:1px solid #ccc}
    .order-left p{margin-bottom: 0;font-size: 14px;}
    .order-right p{margin-bottom: 0;font-size: 14px;}
    // .card-box{margin-bottom: 20px;}
    .card-box .order-body .product-row{margin-bottom: 15px;}
    .card-box .order-body .product-row:last-child{margin-bottom: 0;}
    .my-order.section-pad{padding: 30px 0;}
    .btn-success{background-color: #0AA437;border-color: #0AA437 !important; font-weight: 600; border-radius: 6px; border: none;
      &:hover{color: #fff;background-color: #0AA437;}
    }
    .order-card{padding: 15px;}
    .order-card{
      .order-content{
        h4{font-size: 16px;color: #0AA437;}
        p{font-size: 14px;margin-bottom: 5px;}
      }
    }

    // order Detail
  .order-table {
    margin-top: 20px;
    th{font-size: 16px;background: #0AA437;color:#fff; padding: 10px; vertical-align: middle;
    }
    td{padding: 10px;font-size: 14px;vertical-align: middle;}    
  }
  .detail-footer .detail-grand p{text-align: right;font-size: 14px;margin-bottom: 5px;}
  .detail-footer .detail-grand p:last-child{margin-bottom: 0;}
  .detail-footer .detail-grand label{float:left;font-size: 14px;font-weight: 600;}
  .detail-footer{padding: 15px;border-radius: 8px;}
  .total-line{border-top: 1px dashed #000;padding-top: 10px;margin-top: 10px;}
  .order-track h4{text-align: center;font-size: 20px;}
  .order-track {margin-top: 15px;margin: 0 auto;display: table;width: 400px;}
  .order-track ul{list-style: none;margin: 0;padding: 0;}
  .profile-account h4{font-size: 20px;color: #000;margin-bottom: 10px;}
  .fav-icon{position: absolute;top: 2px;right: 10px;}  
  .fav-icon .iconify{font-size: 24px;color: #ff3333;}
  .cart-option{display: flex;align-items: center;}
  // .cart-option .form-select{height: 28px;}
  .form-group .errortext{float: right;color: #ff3333;display: block;font-size: 12px;text-transform: capitalize;}
  .form-group label{width: 100%;margin-bottom: 5px;font-weight: 500;} 
  .form-group label b{color: #ff3333;font-weight: 500;font-size: 15px;}
  .coupon-cart{margin-bottom: 10px;}
  .free-delivery {position: relative; padding: 10px 10px; border-radius: 6px;background: #DAF1E1;
  
  .rows {
      display: flex; align-items: center;
      .icon {
          margin-right: 10px;
          img {
              width: 70px;
          }
      }
      .content {
          p {
              font-size: 14px; line-height: 18px; font-weight: 500; margin: 0;
              strong {font-weight: 600 !important; color: #0AA437;}
          }
      }
  }  
  &.space {
      margin: 15px 0 !important;
  }
}
.home-btn{background-color: #0AA437 !important;border: none !important;font-size: 18px;font-weight: 600;border-radius: 6px;padding: 10px 15px;color: #fff;}
.cart-body.cardbg{background: #f2f2f2;padding: 15px !important;}
.cardbg{background: #f2f2f2;}
.address-header .badge{color: #fff;padding: 5px 8px;background: #0AA437 !important;font-size: 12px;border-radius: 6px;margin-left: 10px;}
.product-heading h3 span{color: #0AA437 !important;}
// .section-pad{padding-top: 0 !important;}
.order-success{padding-top: 0 !important;}
.order-card .order-content p a:hover{color: #0AA437;}
.cart-price a p:hover{color: #0AA437;}
.delivery-product a:hover{color: #0AA437;}
.mycard-box{margin-bottom: 20px;}
.track-search .form-control{height: 50px;border-radius: 6px;background-color: #F5F5F5;color: 222;border-color: #DDDDDD;border-width: 1.3px;font-size: 14px;box-shadow: none;}
.left-side p{margin-bottom: 5px;font-size: 14px;}
.left-side p:last-child{font-size: 12px;}
.content p{font-size: 12px;color: #6c757d;}
.content p{margin-bottom: 5px;font-size: 14px;}
.accordion-box .accordion .accordion-item .accordion-button {background: #F5F5F5!important;color: #000;font-weight: 600;font-size: 16px;border-radius: 6px 6px 0px 0px !important;}
.accordion-box .accordion .accordion-item .accordion-body {background: #fff!important;color: #000;border-radius: 0 0 6px 6px;border: 1px solid #f5f5f5;}
.accordion-box .accordion .accordion-item .accordion-body p{margin-bottom: 0;font-size: 14px;line-height: 22px;color: #6c757d;}
.track-top h4{font-size: 20px;margin-bottom: 20px;}
.aboutus-box p{font-size: 14px;}
.whyus-box p{font-size: 14px;margin-bottom: 5px;}
.para-text p{font-size: 14px;color: #6c757d;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;overflow: hidden;}
.header .form-group{position: relative;}
.header .form-group .auto-box{position: absolute;left: 0;right: 0;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;padding: 10px 15px;z-index: 999;border-radius: 6px;display: none;background: #fff;}
.header .form-group .auto-box ul{margin: 0;padding: 0;}
.header .form-group .auto-box ul li{list-style: none;margin-bottom: 5px;}
.header .form-group .auto-box ul li a{color: #6c757d;font-size: 13px;display: flex;align-items: center;text-transform: capitalize;}
.header .form-group .auto-box ul li a:hover{color: #0AA437;}
.header .form-group .auto-box{display: block;}
.auto-img{width: 30px;height: 30px;overflow: hidden;margin-right: 10px;}
.auto-img img{object-fit: cover;width: 100%;height: 100%;}
.coupan-content .iconify{font-size: 15px;}
.btn-home{background: #F07C00;color: #fff;border: #F07C00;font-weight: 600;border-radius: 6px;width: 110px;}
.btn-home:active{background-color: #cc6b03 !important;color: #fff !important;}
.btn-home:hover{background-color: #F07C00 !important;color: #fff !important;}
.contact-box{margin-bottom: 40px;}
.contact-box h4{font-size: 28px;}
.contact-box p{font-size: 14px;}
// time line
  .time-line{color: #000;padding: 20px 20px 20px 130px;}
  .time-line ul{border-left: 2px solid #d1d9d9;list-style-type: none;padding: 10px 5px;}
  .time-line ul li{padding: 20px;position: relative;transition: .5s;}
  .time-line ul li:before{background-color: #d1d9d9;
  border-radius: 50%;content: "";height: 20px;left: -16px;position: absolute;top: 28px;transition: .5s;width: 20px;}
  .time-line ul li.active:before{background: #0AA437 !important;}
  .left-side{color: #000;left: -245px;position: absolute;text-align: right;min-width: 190px;top: 15px;}
  .time-line ul li .content {margin-top: -8px;}
  .address-box p a{color: #6c757d;}
  .contact-details .address-list h5{font-size: 16px;margin-bottom: 5px;}
  .contact-details .address-list{margin-bottom: 20px;}
  .address-box p a:hover{color: #0AA437;}
  .contact-details{padding: 10px;margin-bottom: 10px;}
  .contact-right{background-color: #fff;border-radius: 6px;padding: 10px;}
  .empty-cart{width: 300px;height: 400px;margin: 0 auto;}
  .empty-cart .para-text p{font-size: 18px;}
  .no-record{width: 300px;height: 360px;margin: 0 auto;}
  .no-record .para-text p{font-size: 18px;}
  .cart-box{display: none;}
  .contact-img img{width: 90%;}
  // .contact-img{text-align: center;}
  .contact-list ul{list-style: none;margin: 0;padding: 0;}
  .contact-list ul li{position: relative;padding-left: 45px;font-size: 14px;color: #333;margin-bottom: 30px;}
  .contact-list ul li a{font-size: 14px;color: #333;}
  .contact-list ul li a:hover{color: #0AA437;}
  .contact-list ul li .iconify-icon .iconify{position: absolute;left: 0;top: -4px;color: #0AA437;font-size: 16px;width: 30px;height: 30px;border: 1px solid #0AA437;border-radius: 50px;padding: 5px;}
  .contact-list h5{font-size: 24px;margin-bottom: 35px;position: relative;}
  .contact-list h5::before{position: absolute;content: '';width: 210px;height: 1px;background-color: rgba(0, 0, 0, 0.06);bottom: -10px;}
  .contact-right{background: #fff;padding: 30px;border-radius: 20px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;}
  .add-button p{font-size: 14px;color: #6c757d;text-align: center;margin-top: 15px;}
  // empty class
  .empty-box{display: none;}
  .record-box{display: none;} 
  // end
  .toast-box .toast .toast-header{position: fixed;min-width: 225px;height: 45px;z-index: 99;bottom: 10px;right: 0;transform: translate(-50%, -50%);background: #DAF1E1;border-radius: 6px;color: #222;font-size: 13px;padding: 10px 15px;border: 1px solid #0AA537;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;}
  .toast-box .toast .toast-header .iconify{font-size: 22px;margin-right: 2px;color: #0AA537;}
  .form-check-input:checked {background-color: #0AA437 !important;border-color: #0AA437 !important;box-shadow: none !important;}
  .toast-box .toast .toast-header .btn-close{font-size: 12px;}
  .product-cart-list{max-width: 200px;}
  .whyus-box {margin-bottom: 20px;}
  .whyus-box h5{font-size: 18px;}
  .testimonials-box{background-color: #daf1e1;padding: 20px 20px 20px 20px;border-radius: 10px;margin-bottom: 25px;}

  .testimonials-box .testi-box {max-height: 150px; overflow-y: auto; margin-bottom: 20px;}
  .testimonials-box .testi-box p {font-size: 14px !important; color: #6c757d;}
  .quot-box .iconify svg{font-size: 40px;color: #0AA437;}
  .quot-box {margin-bottom: 5px;}
  .testi-right p{margin-bottom: 5px;}
  .testi-img{width: 60px;height: 60px;overflow: hidden;border: 2px solid #0AA437;border-radius: 6px 6px 50px 50px;margin-right: 15px;}
  .testi-img img{width: 100%;height: 100%;object-fit: cover;}
  .testi-right h5{margin-bottom: 0px;font-size: 18px;}
  .testi-right p{margin-bottom: 0px;font-size: 14px;}
  .people-details{display: flex;align-items: center;}
  
//  image viewer
 
.left-load{display: flex;}
.left-1{display: flex;grid: 10px;flex-direction: column;}
.img-wrap{width: 70px;height: 70px;cursor: pointer;border: 1px solid #ccc;margin: 0px 10px 10px 0px;border-radius: 6px;overflow: hidden;}
.img-wrap img{object-fit: cover;width: 100%;height: 100%;}
.left-2{width: 100%;height: 380px;position: relative;overflow: hidden;}
.left-2 .product-offer-box.offer-orange{position: absolute;left: 10px !important;top: 10px;z-index: 99;}
.left-2 img{width: 100%;height: 100%;object-fit: contain;}
.img-wrap.active{border: 1px solid #0AA437 !important;}
.save-price{font-size: 12px !important;color: #0AA437;margin-bottom: 0;}
.read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: .25s ease;
}

.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state ~ .read-more-trigger:before {
  content: 'Show more';
}

.read-more-state:checked ~ .read-more-trigger:before {
  content: 'Show less';
}

.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0 .5em;
  color: #666;
  font-size: .9em;
  line-height: 2;
  border: 1px solid #ddd;
  border-radius: .25em;
}

// new cssfeb23

#more {display: none;}

// loader
.loader-box{
  position: absolute;top: 0;left: 0;overflow: hidden;right: 0;bottom: 0;background: #fff;min-height: 100vh;display: flex;align-items: center;justify-content: center;
}

.loader-text{  
  position: absolute;
  top: 56%;
}
.loader-text p{
   font-size: 14px;   
}

.lds-ripple {
  display: inline-block;position: relative;width: 80px;height: 80px;
}
.lds-ripple div {
  position: absolute;border: 4px solid #0AA537;opacity: 1;border-radius: 50%;animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;left: 36px;width: 0;height: 0;opacity: 0;}
  4.9% {
    top: 36px; left: 36px;width: 0;height: 0;opacity: 0;}
  5% {
    top: 36px;left: 36px;width: 0;height: 0;opacity: 1;}
  100% {
    top: 0px;left: 0px;width: 72px;height: 72px;opacity: 0;}
}
// loader end

// Home skeleton loader 
.skeleton {
	background-color: #e2e5e7;
  width: 100%;
  min-height: 285px;
  border-radius: 20px;
	background-image: linear-gradient(90deg,rgba(#fff, 0),rgba(#fff, 0.5),rgba(#fff, 0));
	background-size: 40px 100%; 
	background-repeat: no-repeat;
	background-position: left -40px top 0;
	animation: shine 1s ease infinite;
  z-index: 9;
}
@keyframes shine {
	to {
		background-position: right -40px top 0;
	}
}
// Home skeleton loader end

.out_of_stock{margin-bottom: 0;font-size: 14px;color: red;font-weight: 500;}
.stock-text{float: left;margin-top: 5px;}
.empty-address{margin-bottom: 0;font-size: 14px;color: #000;}
.carousel-indicators{z-index: 9 !important;bottom: 9px;}
.share-text{font-size: 14px;margin-left: 3px;}
.share-box{cursor: pointer; position: relative;}
.click-effect:hover{color: #0AA537;}
.share-link{ position: absolute;border: none;width: auto;overflow: hidden;right: 0px;top: -3px;left: 68px;width: 185px;}
.share-link .iconify-share{ color: #333;margin: 0px 5px;font-size: 14px;cursor: pointer;vertical-align: middle;}
.cart-title Button{padding: 0;background: none !important;box-shadow: none !important;border: none !important;font-size: 14px;color: #333;font-weight: 600;}
.cart-title{display: flex;justify-content: space-between;margin-bottom: 15px;}
.cart-title Button .iconify{font-size: 15px;margin-right: 2px;}
.order-top{margin-bottom: 30px;}
.category-option .product-count{font-size: 12px;color: #000;float: right;}
.category-option h4{font-size: 14px;color: #000;}
.category-option{background: #fff;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;border-radius: 15px;padding: 10px;margin-bottom: 20px;}
.category-items ul{margin: 0;padding-left: 5px;}
.category-items{max-height: 110px;overflow: hidden;overflow-y: auto;scrollbar-color: #D9D9D9  #F5F5F5;scrollbar-width: thin;}
.category-items ul li{list-style: none;margin-bottom: 8px;display: flex;align-items: center;}
.category-items ul li a{color: #6c757d;font-size: 14px;display: flex;align-items: center;margin-left: 8px;}
.category-items ul li a:hover{color: #0AA537;}
.category-list{margin-top: 30px;}
.shop-bottom{margin-bottom: 15px;}
.product-title .skeleton-loader{margin-top: 5px;}
.text-grey{font-size: 12px !important;}

// loader skeloton product
.skeleton-loader{
  background-color: #e2e5e7;border-radius: 4px;background-image:linear-gradient(90deg,rgba(#fff, 0),rgba(#fff, 0.5),rgba(#fff, 0));background-size: 40px 100%;background-repeat: no-repeat;background-position: left -40px top 0;animation: shine 1s ease infinite;}

  @keyframes shine {
    to {
      background-position: right -40px top 0;
    }
  }

  // loader skeloton product end

  // loader style product
  .product-title {
    &.skeleton-loader {
      min-height: 28px;border-radius: 4px;margin-top: 3px;
    }
  }

  .price-list {
    &.skeleton-loader { 
      min-height: 40px;border-radius: 4px;
    }
  }

  .cart-btn {
    
    &.skeleton-loader {
      min-height: 40px;border-radius: 4px;
    }
  }
  .product-icon {
    &.skeleton-loader {
      min-height: 20px;border-radius: 4px;
    }
  }

  .offer-orange {
    &.skeleton-loader {
      min-height: 20px;border-radius: 4px;background:none !important;
    }
  }
  // loader style product


  // product detail loader

  .left-1{
    &.skeleton-loader {
      height: 400px;border-radius: 4px;width: 90px;margin-right: 10px;
    }
  }

  .left-2 {
    &.skeleton-loader { 
      height: 400px;border-radius: 4px;width: 350px;
    }
  }
  .product-list{
    &.skeleton-loader {
      height: 20px;border-radius: 4px;width: 100%;margin-right: 10px;
    }
  }

  .product-content{
    &.skeleton-loader { 
      height: 30px;border-radius: 4px;width: 400px;margin-bottom: 15px;
    }
  }
  .product-price{
    &.skeleton-loader { 
      height: 40px;border-radius: 4px;width: 100px;margin-bottom: 15px;
    }
  }
  .product-add{
    &.skeleton-loader { 
      height: 50px;border-radius: 4px;width: 150px;margin-bottom: 15px;
    }
  }
  .product-share{
    &.skeleton-loader { 
      height: 30px;border-radius: 4px;width: 220px;margin-bottom: 15px;
    }
  }
  .product-desription{
    &.skeleton-loader { 
      height: 90px;border-radius: 4px;width: 700px;margin-bottom: 15px;
    }
  }
  .product-tags{
    &.skeleton-loader { 
      height: 40px;border-radius: 4px;width: 400px;margin-bottom: 15px;
    }
  }
  .product-delivery{
    &.skeleton-loader { 
      height: 80px;border-radius: 4px;width: 700px;margin-bottom: 15px;
    }
  }


  .skeleton-boxtow{margin-left: 30px;}
  
  // loader style product



  // search bar
  .left-search{
    &.skeleton-loader {
      min-height: 30px;border-radius: 4px;width: 35px;margin-right: 5px;
    }
  }

  .right-search {
    &.skeleton-loader { 
      min-height: 30px;border-radius: 4px;width: 100%;
    }
  }
  // Search bar end

  
  .header .form-group .auto-box .auto-list{max-height: 180px;overflow-y: auto;scrollbar-color: #D9D9D9 #F5F5F5;scrollbar-width: thin;position: relative;}
 .view-btn.search-view{margin-top: 0 !important;padding-top: 0px;position: sticky;background: #fff;bottom: 0;left: 0;right: 0;}
 .view-btn.search-view a{padding: 6px 14px;font-size: 10px;color:#222;border: none;}
 .view-btn.search-view a:hover{color: #0AA537;}
 .empty-text p{font-size: 20px;font-weight: 800;color: #6c757d;text-align: center;}
 .category-type{position: sticky;top: 100px;}
 .down-icon .iconify{font-size: 12px;color: #000;float: right;display: none;}
 .header-category{width: 110px !important;background-color: #0AA537;border-radius: 5px; margin-right: 10px !important;}
 .account-box.header-category .nav-link{color: #fff !important;padding: 14px 30px 14px 15px !important;font-size: 14px;}
 .account-box.header-category .list-arrow{color: #fff;top: 6px !important;font-size: 24px;}
 .account-box.header-category .menu-icon{top: 7px;left: 12px;color: #fff;font-size: 24px;}
 .category-flex{display: flex;align-items: center;justify-content: flex-end;}
 .dropdown-box .drop-left{padding: 10px 10px 10px 10px!important;}
//  .account-box.header-category  .dropdown-menu .dropdown-item.active{background-color: #eeeeee;border-radius: 5px;}
 .account-box.header-category  .dropdown-menu{min-width: 250px !important;padding: 5px;}
 .account-box.header-category  .dropdown-menu .dropdown-item:hover{background-color: #eeeeee;border-radius: 5px;}
 .card-box ul button{
  background: none;border: none;appearance: none;font-size: 13px;color: #222;
  .iconify {margin-right: 5px;}
}
.detail-add{height: 44px !important;}
.invalid-feedback{text-transform: capitalize;}
.select-card .cart-btn.btn-big a{font-size: 14px;padding: 12px 14px 12px 14px;}
.select-card .price-add{height: 40px;width: 105px;}
.text-product{font-size: 28px !important;}
.left-size{max-height: 370px;overflow-y: auto;scrollbar-color: #D9D9D9 #F5F5F5;scrollbar-width: none;width: 100px;margin-right: 15px;}
.left{display: flex;}
.skeleton-boxone{display: flex;}
.error{font-size: 12px;margin-bottom: 0;margin-top: 5px;}
.alertModals .modal-dialog .modal-content .cart-price-modal p{margin-bottom: 0;font-size: 16px;text-align: start;}
.cart-img-modal{width: 50px;height: 50px;overflow: hidden;}
.cart-img-modal img{width: 100%;object-fit: cover;}
.pop-box-space{padding: 15px 0;}
.cart-pop-box{margin-bottom: 10px;}
.header .form-group .auto-box ul li .no-result{font-size: 14px; text-align: center;}
.checkout-popup{height: 200px;overflow-y: auto;scrollbar-color: #D9D9D9 #F5F5F5;scrollbar-width: thin;margin-right: 5px;overflow-x: hidden;}
.checkout-popup .modal-body h6{font-size: 14px;}
.Category-box:nth-child(2){margin-bottom: 0 !important;}
.banner-slider .carousel-item{width: 100%; height: 350px; overflow: hidden;}
.banner-slider .carousel-item img{width: 100%; height: 100%; object-fit: cover;}

.banner-slider.popup-banner {
  .carousel-item {
    height: 400px;
    // img {
    //   width: auto !important;
    //   height: inherit !important;
    // }
  }
}
.slider.mainSlider {padding-bottom: 10px !important;}
  

// responsive

// mobile view 
@media (max-width: 767px) {
  .banner-slider .carousel-item{height: 100px;}
  .Category-box:nth-child(2){margin-bottom: 0 !important;}
  .skeleton-boxtow{margin-left: 0px;margin-top: 30px;}
  .left-load{display: unset;}
  .left-1{
    &.skeleton-loader {
      height: 300px;border-radius: 4px;width: 60px;margin-right: 10px;
    }
  }

  .left-2 {
    &.skeleton-loader { 
      height: 300px;border-radius: 4px;width: 250px;
    }
  }
  .product-list{
    &.skeleton-loader {
      height: 20px;border-radius: 4px;width: 100%;margin-right: 10px;
    }
  }

  .product-content{
    &.skeleton-loader { 
      height: 30px;border-radius: 4px;width: 250px;margin-bottom: 15px;
    }
  }
  .product-price{
    &.skeleton-loader { 
      height: 40px;border-radius: 4px;width: 100px;margin-bottom: 15px;
    }
  }
  .product-add{
    &.skeleton-loader { 
      height: 50px;border-radius: 4px;width: 150px;margin-bottom: 15px;
    }
  }
  .product-share{
    &.skeleton-loader { 
      height: 30px;border-radius: 4px;width: 220px;margin-bottom: 15px;
    }
  }
  .product-desription{
    &.skeleton-loader { 
      height: 90px;border-radius: 4px;width: 320px;margin-bottom: 15px;
    }
  }
  .product-tags{
    &.skeleton-loader { 
      height: 30px;border-radius: 4px;width: 300px;margin-bottom: 15px;
    }
  }
  .product-delivery{
    &.skeleton-loader { 
      height: 50px;border-radius: 4px;width: 320px;margin-bottom: 15px;
    }
  }
  .left-2 {width: 100%;height: 290px;position: relative;overflow: hidden;}
  .left-size{max-height: 285px;overflow-y: auto;scrollbar-color: #D9D9D9 #F5F5F5;scrollbar-width: none;width: 80px;margin-right: 5px;}
  .select-card .btn-full{text-align: center ;}
  .select-card .btn-full a{padding: 8px 12px 8px 12px;}
  .select-card .cart-btn.btn-big a{font-size: 14px;padding: 10px 10px 10px 10px;}
  .logo-point img {width: 90px;}
  .img-wrap{width: 60px;height: 60px;}
  .select-card .price-add{width: 100%;height: 44px;}
  .share-link{width: 135px;}
  .product-offer-box span strong{font-size: 13px;}
  .carousel-control-prev{display: none;}
  .carousel-control-next{display: none;}
  .empty-text{font-size: 14px;font-weight: 800;color: #6c757d;text-align: center;}
  .down-icon .iconify{font-size: 18px;color: #000;float: right;display: unset;}
  .category-type{margin-bottom: 20px;}
  .view-btn.search-view a{padding: 4px 16px;font-size: 10px;}
   header .nav-link {text-align: left;}
  .carousel-indicators {z-index: 9 !important;bottom: -10px;}
  .empty-cart {width: 180px;height: 200px;margin: 0 auto;}
  .no-record {width: 180px;height: 250px;margin: 0 auto;}
  .select-card .cart-btn{margin-top: 5px; text-align: center;}
  .select-card .btn-add{text-align: center !important;}
  .select-card .btn-add.btn-left{text-align: start !important;}
  .out_of_stock{text-align: center;}
  .Category-list{padding-top: 30px;}
  .accordion-box .accordion .accordion-item .accordion-body p{font-size: 12px;}
  .accordion-box .accordion .accordion-item .accordion-button {font-size: 12px;}
  .skeleton{min-height: 110px;border-radius: 6px;}
  .loader-box{
    max-height: 400px;
  }
  .left-1{display: flex;grid: 10px;}
  .btn-size{height: 50px !important;}
  .cart-left{padding: 0 !important;}
  .address-box p:last-child{margin-bottom: 0 !important;}
  .owl-dots .owl-dot span{width: 12px !important;height: 12px !important;}
  .detail-info{margin-top: 10px;}
  .buy-box{margin-top: 10px;}
  .quot-box{margin-bottom: 5px;}
  .order-card .order-content p:last-child{margin-bottom: 0;}
  .order-card .order-content p strong{font-size: 13px;}
  .save-action .iconify{font-size: 24px;}
  .qty-button{width: 25px;height: 25px;padding: 12px;margin-top: 5px;padding: 14px;}
  .cart-total h4{font-size: 18px;}
  .price-value{font-size: 18px;}
  .breadcrumb ul li a .iconify{font-size: 21px;}
  .related-product .owl-carousel .owl-dots{margin-top: 0 !important;}
  .Category-title .shop-btn{font-size: 14px;}
  .track-cart a .iconify{font-size: 13px;}
  .table {width: 600px;}
  .product-cart-list{max-width: 260px;}
  .cart-body table{width: 600px !important;}
  .copyright p{font-size: 12px;}
  .header .navbar-toggler{padding: 0 !important;}
  .category-box{border-radius: 6px;}
  .contact-right{padding: 20px;}
  .add-button{margin-left: 5px;}
  .add-button .btn-cancel{margin-right: 5px;}
  .cart-img{width: 80px;height: 80px;}
  .cart-table tr td{padding: 5px;}
  .carousel-inner{border-radius: 6px;}
  .header .form-group .auto-box{padding: 10px;}
  .header .form-group .auto-box ul li a{font-size: 12px;}
  .contact-box h4{font-size: 22px; margin-bottom: 10px;}
  .contact-list h5::before{margin-bottom: 5px;}
  .contact-list h5{font-size: 18px;margin-bottom: 35px;}
  .contact-img img{width: 80%;}
  .my-order{padding-top: 0 !important}
  .btn-home{padding: 8px 10px !important;font-size: 14px;min-width: 100px;}
  .price-list h5 br{display: none;}
  .price-list h5{font-size: 10px;}
  .product-title{margin-bottom: 0;}
  .social-icon h3{font-size: 16px;margin-bottom: 0;}
  .time-line {  padding: 20px 20px 20px 180px;}
  .left-side p{font-size: 12px;}
  .content p{font-size: 12px;line-height: 16px;}
  .track-search{margin-bottom: 10px;}
  .track-top h4{font-size: 20px;margin-bottom: 10px;}
  .header .form-group .input-group{width: 170px;}
  .works-box .steps{top: -10px;left: -5px;font-size: 25px;}
  .footer-widget{margin-bottom: 20px;}
  .footer-widget h3{margin-bottom: 10px;font-size: 16px;}
  .footer-section {background: #DAF1E1;padding: 20px 0px;}
  .copyright {text-align: center;font-size: 14px;padding: 20px 0px 0px 0px;margin-top: 20px;border-top: 1px solid #979797;}
  .footer-section{padding: 30px 0px 20px 0px;}
  .app-img{text-align: center;}
  .app-img img{width: 230px;margin: 0 auto;}
  .app-logo {width: 210px;height: 210px;background: #fff;border-radius: 50%;display: flex;align-items: center;justify-content: center;margin: 0 auto;display: none;}
  .logo-box img{width: 140px;}
  .app-detail h3{font-size: 24px;}
  .app-detail h4{font-size: 24px;}
  .app-detail p{font-size: 12px;}
  .app-detail{margin: 10px 0;text-align: center;}
  .app-download{text-align: center;}
  section{padding: 20px 0;}
  .pb-space{padding-bottom: 0 !important;}
  .pt-space{padding-top: 0 !important;}
  .store-group {
    background: #fff; border-radius: 8px; padding: 10px;    
  }
  
  .form-option .form-select{height: 33px;font-size: 13px;width: 100%;}
  .whychoose-box h4{font-size: 16px;}
  .whychoose-box p{font-size: 12px;}
  .whychoose-box{position: relative;}
  .breadcrumb ul li{font-size: 13px;}
  .choose-img img{width: 50px;}
  .detail-box{min-height: 283px !important;}
  .product-heading h3{font-size: 22px;}
  .product-heading h3 span{color: #0AA437 !important;}
  .product-box{margin-bottom: 20px;min-height: 282px;padding: 10px;}
  .view-btn{margin-top: 10px;}
  .view-btn a{padding: 10px 30px;font-size: 12px;}
  .cart-btn .iconify {font-size: 15px;}
  .product-img{height: 150px;}
  .product-icon img{width: 50px;}
  .product-icon {position: absolute;right: 10px;bottom: 10px;}
  .product-icon.product-icon-detail{right: 15px; bottom: 15px; z-index: 9;}
  .product-title h4{font-size: 13px;}
  .product-offer-box{width: 40px;height: 40px;overflow: hidden;color: #fff;border-radius: 8px 8px 50px 50px;text-align: center;font-size: 11px;line-height: 14px;padding: 4px;position: absolute;left: 10px;top: 10x;}
  .price-tag {font-size: 16px;font-weight: 600;}
  .category-box{margin-bottom: 30px;height: 140px;transition: all 0.2s ease !important;}
  .fruits-img{width: 175px;height: 140px;overflow: hidden;position: absolute;top: 20px;left: 7px;}
  .Category-title span{font-size: 16px;}
  .Category-title{right: 20px;}
  .Category-title h3{font-size: 22px;}
  .Category-title a{font-size: 12px;padding: 7px 20px;}
  .offer-box strong{font-size: 14px;}
  .offer-box{font-size: 10px;line-height: 13px;width: 50px;height: 50px;}
  .fruit-two{width: 150px !important;height: 160px !important;top: 16px !important;left: 28px !important;}
  .fruit-three{width: 150px !important;height: 125px !important;top: 60px !important;left: 35px !important;}
  header .navbar-brand img{width: 50px;}
  header .order-box span{font-size: 14px !important;}
  header .order-box p{font-size: 14px;}
  header .order-box{padding-left: 0;}
  header .order-box p{padding-left: 24px;}
  header .order-box .iconify{left: 0;}
 // .slider{padding-top: 20px !important;}
  header .form-group .search-bar .form-control{height: 40px;font-size: 12px;padding: 5px 30px 5px 10px;}
  header .search-icon .iconify{top: 6px;right: 4px;}
  header .iconify-arrow-header {position: absolute;right: 20px;top: 5px;font-size: 28px !important;}
  .product-heading{margin-bottom: 20px;}
  // .navbar-logo{display: block;}
  .navbar-logo img{width: 55px !important;}
  .mail-box{display: none;}
  .location-mobile-view{display: block !important;}
  .location-box{display: none;}
  header .location-box .iconify{font-size: 18px;top: 6px;}
  .account-box{display: none;}
  // .carousel-indicators{bottom: -35px !important;}
  .search-cart{display: flex;justify-content: space-between;align-items: center;}
  .search-cart .account-cart{display: block;padding: 0;}
  .card-box{display: none;}
  .place-order{display: none;}
  header .account-cart .iconify {position: absolute;left: -7px;top: 4px;font-size: 20px;}
  header .account-cart .cart-count{left: 3px;top: -5px;}
  .user-account .iconify{font-size: 20px;}
  header .account-cart .iconify{position: unset;}
  header .user-account .nav-link{padding: 3px !important;}
  .navbar-toggler-icon{background: url('../public/assets/images/baseline-menu.svg') no-repeat center center !important;}
  .user-account{display: block;}
  .user-account button{-webkit-appearance: none !important;padding: 0;background: none !important;box-shadow: none !important;border: none !important;font-size: 14px;color: #333;}
  .login-cont{width: 315px;}
  .product-heading h3 span{font-size: 24px !important;font-weight: 600;}
  .works-box p span{font-weight: 600;font-size: 14px;}
  .works-img {width: 100px;height: 100px;margin: 0 auto 5px;}
  .works-box p{font-size: 14px;line-height: 18px;}
  .popup-banner{display: none;}
  .delivery-box{display: none;}
  .dropdown-item {position: relative; padding-left: 35px !important;}
  .dropdown-item .iconify-drop {position: absolute;left: 10px;top: 8px;font-size: 14px;}
  .works-img img{width: 110px;}
  .footer-logo{margin-bottom: 10px;}
  .text-green {color: #0AA537;font-weight: 600; font-size: 14px;}
  .text-mail{font-size: 14px;}
  .detail-add{height: 35px !important;}
  .slider{margin-top: 110px !important;}
  .contact-box p{font-size: 12px;}
  .contact-box p br{display: none;}
  // .cart-hide{display: none;}
  .cart-btn a{font-size: 12px;padding: 8px 12px 8px 12px;display: inline-block !important;text-align: center !important;}
  // .product-title{height: 20px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
  .item-list p{font-size: 13px;}
  .price-option .form-select{width: 124px;font-size: 13px;height: 30px;}
  .price-option span{font-size: 13px;}
  .price-list{margin-top: 5px;}
  .border-line-top{margin-top: 0px;margin-bottom: 10px;}
  .breadcrumb{padding: 0 0 10px 0;}
  .cart-title h4{font-size: 16px;margin-bottom: 0;}
  .cart-title Button{padding: 0;background: none !important;box-shadow: none !important;border: none !important;font-size: 13px;color: #333;font-weight: 600;}
  .breadcrumb ul li a{font-size: 13px;padding-right: 23px;}
  .address-box{margin-top: 10px;padding: 15px;}
  .delivery-option{margin-bottom: 10px;}
  .product-dt-view{margin-bottom: 10px;}
  .product-dt-right h4{font-size: 16px;}
  .tags ul li a{font-size: 12px;}
  .tags ul li{font-size: 14px;margin-bottom: 15px;margin-right: 8px;}
  .delivery-value{margin-top: 0;}
  .coupon-cart{margin-bottom: 5px;}
  .cart-body .delivery-option .address-title h5{font-size: 14px;}
  .order-box .success-icon .iconify{font-size: 50px;}
  .order-box h4{font-size: 16px;}
  .order-box h4 br{display: none;}
  // .order-box p{font-size: 14px;}
  .order-box{padding: 0 10px;}
  .order-box .btn-success{padding: 8px 10px;font-size: 14px;min-width: 100px;}
  .mt-space{margin-top: 110px !important;}
  .free-delivery{margin-top: 10px !important;padding: 5px;
    .rows{
      .content{
         p{font-size: 12px;}
      }
    }
  
  }
  .cart-title{margin-bottom: 10px;}
  .address-header h5{font-size: 16px;}
  .address-header .badge{padding: 5px;font-size: 10px;margin-left: 5px;}
  .bottom-space{margin-bottom: 0 !important;}
  .order-menu ul li{padding-bottom: 5px !important;}
  .order-menu ul li a{padding: 0 20px;}
  .order-menu{margin-bottom: 15px;display: none;}
  .track-cart a{font-size: 10px;padding: 4px 5px;display: inline-block;}
  .order-right{text-align: left !important;}
  .delivery-detail p{font-size: 13px;}
  .order-left p{font-size: 13px;}
  .mycard-box{margin-bottom: 15px;}
  .order-top h4{font-size: 18px;}
  .order-content{text-align: start !important;margin-bottom: 10px;}
  .fav-icon .iconify{font-size: 22px;}
  .alertModals {
    .modal-dialog {
      width: 90% !important; margin: 0 auto;
    }
  }
  .cart-btn .btn-add .btn-left{text-align: start !important;}
  .footer-widget p{ font-size: 12px;}

  .order-success, .my-order {
    min-height: inherit !important;
  }

  .order-track {
    .time-line {
      .left-side {
        left: -180px !important;
        width: 150px !important;
      }
    }
  }

  }
  
 

// tab view

@media only screen and (max-width: 1023px) and (min-width: 767px)  {
  .banner-slider .carousel-item{height: 200px;}
  .select-card .btn-full{text-align: center ;}
  .carousel-control-prev{display: none;}
  .carousel-control-next{display: none;}
  .skeleton-boxtow{margin-left: 20px;}
  .left-1{
    &.skeleton-loader {
      height: 300px;border-radius: 4px;width: 60px;margin-right: 10px;
    }
  }

  .left-2 {
    &.skeleton-loader { 
      height: 300px;border-radius: 4px;width: 180px;
    }
  }
  .product-list{
    &.skeleton-loader {
      height: 20px;border-radius: 4px;width: 100%;margin-right: 10px;
    }
  }

  .product-content{
    &.skeleton-loader { 
      height: 30px;border-radius: 4px;width: 250px;margin-bottom: 15px;
    }
  }
  .product-price{
    &.skeleton-loader { 
      height: 40px;border-radius: 4px;width: 100px;margin-bottom: 15px;
    }
  }
  .product-add{
    &.skeleton-loader { 
      height: 50px;border-radius: 4px;width: 150px;margin-bottom: 15px;
    }
  }
  .product-share{
    &.skeleton-loader { 
      height: 30px;border-radius: 4px;width: 220px;margin-bottom: 15px;
    }
  }
  .product-desription{
    &.skeleton-loader { 
      height: 90px;border-radius: 4px;width: 400px;margin-bottom: 15px;
    }
  }
  .product-tags{
    &.skeleton-loader { 
      height: 30px;border-radius: 4px;width: 300px;margin-bottom: 15px;
    }
  }
  .product-delivery{
    &.skeleton-loader { 
      height: 50px;border-radius: 4px;width: 400px;margin-bottom: 15px;
    }
  }
  .select-card .cart-btn.btn-big a{font-size: 14px;padding: 10px 10px 10px 10px;}
  .free-delivery .rows .content p{font-size: 12px;}
  .free-delivery .rows .icon img {width: 40px;}
  header .nav-link {text-align: left;}
  header .navbar-toggler{padding-right: 0px;}
  .logo-point img {width: 90px;}
  .select-card .price-add{width: 85px; height: 38px;}
  .Category-title .shop-btn{padding: 7px 15px;font-size: 12px;}
  .img-wrap{width: 50px;height: 50px;}
  .auto-img img{object-fit: contain;width: 100%;height: 100%;}
  .auto-img {width: 50px;height: 50px;overflow: hidden;margin-right: 12px;}
  .header .form-group .auto-box ul li a{font-size: 12px;}
   header .account-box.header-category{display: none;}
  .account-box.header-category .list-arrow {color: #fff;top: 3px !important;font-size: 24px;right: 28px;}
  .account-box.header-category .menu-icon {top: 7px;left: 7px;color: #fff;font-size: 18px;}
  .account-box.header-category .nav-link {color: #fff !important;padding: 12px 30px 12px 30px !important;font-size: 12px;}
  .header-category {width: 110px !important;background-color: #0AA537;border-radius: 5px;}
  .carousel-indicators {z-index: 9 !important;bottom: -6px;}
  .skeleton{min-height: 230px;}
  .price-list h5 br {display: none;}
  .btn-size{font-size: 14px;}
  .profile-account h4{font-size: 16px;}
  .address-box{margin-bottom: 15px;}
  .address-header h5{font-size: 14px;}
  .mycard-box{margin-bottom: 15px;}
  .order-top h4{font-size: 16px;}
  .btn-order{font-size: 14px;width: 110px;}
  .order-box .success-icon .iconify{font-size: 40px;}
  .order-box h4{font-size: 16px;}
  .order-success{padding-top: 0 !important;}
  .order-box p{font-size: 14px;}
  .cart-title h4{font-size: 16px;}
  .product-dt-right h4{font-size: 20px;}
  .free-delivery{margin-top: 10px !important;}
  .tags ul li{margin-bottom: 20px;}
  .breadcrumb {padding: 0 0 10px 0;margin-bottom: 10px;}
  .footer-widget h3{margin-bottom: 10px;}
  .footer-widget{margin-top: 20px;}
  .copyright{padding-top: 30px;margin-top: 20px;}
  section{padding: 30px 0;}
  .footer-section {padding: 30px 0px;}
  .app-img img{width: 200px;}
  .app-logo {width: 190px;height: 190px;}
  .logo-box img{width: 150px;}
  .app-detail h3{font-size: 26px;}
  .app-detail h4{font-size: 24px;}
  .app-detail p{font-size: 13px;}
  .appstore-img img{width: 85px;}
  .qr-img img{width: 60px;}
  .pb-sapce{padding-bottom: 0;}
  .product-heading h3{font-size: 24px;}
  .choose-img img{width: 45px;}
  .view-btn{margin-top: 10px;}
  .view-btn a{font-size: 13px;padding: 10px 30px;}
  .whychoose-box h4{font-size: 18px;}
  .cart-btn a{padding: 7px 7px 7px 7px;font-size: 9px;}
  .product-icon img{width: 50px;}
  .product-icon {position: absolute;right: 10px;bottom: 10px;}
  .product-offer-box span{font-size: 12px;}
  .product-offer-box span strong{font-size: 16px;}
  .product-img{height: 190px;}
  .product-box{min-height: 313px;}
  .offer-box{font-size: 8px;width: 50px;height: 50px;line-height: 13px;}
  .offer-box strong{font-size: 16px;}
  .Category-title span{font-size: 12px;}
  .Category-title h3{font-size: 22px;}
  .fruit-three{width: 160px !important;height: 135px !important;top: 88px !important;left: -4px !important;}
  .fruit-two{width: 135px !important;height: 145px !important;top: 71px !important;left: -6px !important;}
  .fruits-img{width: 165px;height: 145px;top: 72px;left: 2px;}
  .Category-title a{padding: 7px 15px;font-size: 12px;}
  .category-box{height: 205px;}
  header .navbar-brand img{width: 60px;}
  header .form-group .search-bar .form-control{height: 45px;font-size: 12px;padding: 5px 30px 5px 10px;}
  header .order-box{padding-left: 5px;}
  header .order-box span{font-size: 12px;}
  header .order-box .iconify{font-size: 16px;left: 3px;}
  header .order-box p {padding-left: 22px;font-size: 13px;}
  header .search-icon .iconify {position: absolute;right: 6px;top: 7px;z-index: 999;font-size: 18px;}
  header .iconify-arrow-header {position: absolute;right: 20px;top: 3px;font-size: 28px !important;}
  .navbar-toggler-icon{background: url('../public/assets/images/baseline-menu.svg') no-repeat center center !important;}
  .mail-box{display: none;}
  .location-mobile-view{display: block !important;}
  .location-box{display: none;}
  .slider {margin-top: 125px;}
  .works-img{width: 140px;height: 140px;}
  .works-box p{font-size: 14px;line-height: 18px;}
  .works-box .steps{font-size: 28px;left: -30px;top: -10px;}
  .product-heading {margin-bottom: 30px;}
  .qty-button{padding: 14px;}
  .empty-cart {width: 230px;height: 250px;margin: 0 auto;}
  .no-record {width: 230px;height: 250px;margin: 0 auto;}
  .search-cart{width: 160px;}
  }

  @media screen and (min-width: 992px) and (max-width: 1200px){

    .header-bottom {
      .cols-search {
        width: 40%;
    }
    .cols-account {
        width: 25%;
    }
    }

    .carousel-control-next{
      right: -70px;
    }
    .carousel-control-prev{
      left: -70px;
    }





  }



  .alertModals {
  .modal-dialog {
    width: 400px; 
    .closeModal {
      background: none !important; border: none !important; box-shadow: none !important; color: #666; font-size: 24px !important; position: absolute; top: 0; right: 10px; z-index: 9; padding: 5px !important;
      &:hover {color: #06852c;}
    }
    .modal-content {
      text-align: center; padding: 50px 20px;
      h5 {
        font-size: 24px; font-weight: 600; margin: 0 0 10px;
      }
      p {
        font-size: 14px; margin: 0 0 15px; color: #666;
      }
      button {
        font-size: 16px; padding: 5px 40px; border-radius: 6px; margin-left: 10px;
      }
      .btn-primary {
        background: #06852c; border-color: #06852c;
        &:hover {
          background: #036320; border-color: #036320;
        }
      }
    }
  }  
}
.razorpay-backdrop{
  background: #dddbdb !important;
}

// css may16

.para-text span{ font-size: 14px !important; color: #6c757d;}
.view-btn-green a:hover{background-color: #06852c; color: #fff; border-color: #06852c;}

@media (min-width: 1200px) and (max-width: 1400px) {
  .cols-search {
      width: 45%;
  }
  .cols-account {
      width: 20%;
  }
}

.highlighted {
//  background-color: #f0f0f0;
  background-color: #e2e0e0;
  transition: background-color 0.3s ease;
}

.terms_section p{font-size: 14px;color: #6c757d; margin-bottom: 15px;}
.terms_section br {margin-bottom: 15px;}
.terms_section a {color: #0AA437;}

.delivery-box p span {
  color: #0AA437;
}

.order-success, .my-order {
  min-height: 360px;
}

@media (min-width: 1920px) and (max-width: 2560px)  {
  .order-success, .my-order {
    min-height: 550px;
  }
}

@media (min-width: 768px) and (max-width: 1399px)  {
  .banner-slider .carousel-item {
    img {
      object-fit: contain !important;
    }
  }
}
.footer-logo-name h5 span{color: #0AA437 !important;}

.success-icon.failed .iconify{
  color: #e42121 !important;
}
